var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-table',{attrs:{"hoverable":"","data":_vm.allStageIIWrappers,"row-class":function (row, index) { return row.response.syncType !== 'deletion' && !row.response.kitId
          ? ''
          : 'tw-cursor-pointer'; }},on:{"click":_vm.openKitBuilder}},[_c('b-table-column',{attrs:{"label":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',{class:[
          props.row.response.syncType !== 'deletion' &&
          !props.row.response.kitId
            ? 'tw-text-gray-400'
            : '' ]},[_vm._v(_vm._s(props.row.name))])]}}])}),_c('b-table-column',{attrs:{"width":"25%","label":"Changes"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.row.state == 'posting')?_c('b-icon',{staticClass:"tw-text-gray-400",attrs:{"icon":"circle-notch","size":"is-small","custom-class":"fa-spin"}}):(props.row.state == 'error')?_c('b-icon',{staticClass:"tw-text-red-500",attrs:{"icon":"exclamation"}}):(
          props.row.state == 'completed' &&
          (props.row.response.syncType == 'addition' ||
            props.row.response.syncType == 'undeletion')
        )?_c('div',{staticClass:"tw-font-bold tw-text-green-500"},[_vm._v(" Added ")]):(
          props.row.state == 'completed' &&
          props.row.response.syncType == 'deletion'
        )?_c('div',{staticClass:"tw-text-red-500 tw-font-bold"},[_vm._v(" Deleted ")]):(
          props.row.state == 'completed' &&
          props.row.response.syncType == 'update'
        )?_c('div',{staticClass:"tw-text-blue-500 tw-font-bold"},[_vm._v(" Update ")]):_vm._e()]}}])}),_c('b-table-column',{attrs:{"width":"15%","label":"Actions"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.row.response.syncType !== 'deletion')?_c('b-button',{staticClass:"show-on-hover",attrs:{"disabled":!props.row.response.kitId,"size":"is-small","type":"is-primary"},on:{"click":function($event){$event.stopPropagation();return _vm.openKitBuilder(props.row)}}},[_vm._v("Configure")]):_vm._e(),(props.row.response.syncType !== 'deletion')?_c('b-button',{staticClass:"tw-ml-2 show-on-hover",attrs:{"kitId":props.row.kitId,"kit":props.row,"disabled":!props.row.response.kitId,"size":"is-small","outlined":"","type":"is-primary"},on:{"click":function($event){$event.stopPropagation();return _vm.openShareKitModal(props.row)}}},[_vm._v("Share")]):_vm._e()]}}])}),(_vm.isShareKitModalActive)?_c('UltimateShareSidePanel',{attrs:{"kitId":_vm.shareKitModalKitId,"kitName":_vm.shareKitModalKitName,"shareType":"kit","isCopycatKit":false},on:{"close":function($event){_vm.isShareKitModalActive = false}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }