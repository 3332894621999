var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.activeSidePanel)?_c('b-dropdown',{staticClass:"tw-absolute",staticStyle:{"z-index":"100"},attrs:{"aria-role":"menu","position":"is-bottom-left"},on:{"active-change":function($event){return _vm.$emit('active-change', $event)}},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('a',[_c('b-icon',{staticClass:"hover:tw-text-black tw-text-gray-800",attrs:{"icon":"ellipsis-h","size":"is-small"}})],1)]},proxy:true}],null,false,4142503088)},[(
        !_vm.cannotEditKit &&
        !_vm.isCmd &&
        !_vm.viewingPublicly &&
        _vm.legoDoll.executionEnv !== 'variable'
      )?_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":function($event){return _vm.$emit('emitOpenLegoSidePanel', {
          kitInstanceId: _vm.cell !== null ? _vm.cell.kitInstanceId : null,
        })}}},[_vm._v("Configure Command")]):_vm._e(),(
        !_vm.cannotEditKit &&
        !_vm.isCmd &&
        !_vm.viewingPublicly &&
        _vm.legoDoll.executionEnv !== 'variable'
      )?_c('hr',{staticClass:"dropdown-divider"}):_vm._e(),(_vm.legoDoll.kitRunSchema.length > 0 && _vm.isCmd)?_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":function($event){return _vm.$emit('emitSaveThisAs')}}},[_vm._v("Save This Search")]):_vm._e(),(_vm.isCmd)?_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":function($event){return _vm.$emit('emitOpenSavedSearchesSidePanel')}}},[_vm._v("Saved Searches")]):_vm._e(),_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":function($event){return _vm.$emit('emitOpenHistorySidePanel', _vm.historySidePanelOlId)}}},[_vm._v("History")]),(
        _vm.legoDoll.kitRunSchema.length > 0 ||
        (_vm.isCmd && _vm.legoDoll.executionEnv !== 'variable')
      )?_c('hr',{staticClass:"dropdown-divider"}):_vm._e(),(_vm.legoDoll.kitRunSchema.length > 0)?_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":_vm.clearSchema}},[_vm._v("Clear Inputs")]):_vm._e(),(_vm.isCmd && _vm.legoDoll.executionEnv !== 'variable')?_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":function($event){return _vm.$emit('emitClearKitRuns')}}},[_vm._v(" Clear Output ")]):_vm._e(),(_vm.legoDoll.executionEnv !== 'variable')?_c('b-dropdown-item',{attrs:{"aria-role":"listitem","disabled":_vm.legoDoll.kitRunSchema.length === 0},on:{"click":function($event){return _vm.$emit('emitOpenBulkRunSP', {
          schema: _vm.legoDoll.kitRunSchema,
          kitInstanceId: _vm.cell !== null ? _vm.cell.kitInstanceId : null,
          legoName: _vm.legoDoll.legoName,
        })}}},[_vm._v(" Run in Bulk ")]):_vm._e(),(!_vm.viewingPublicly && _vm.isCmd && _vm.legoDoll.executionEnv !== 'variable')?_c('hr',{staticClass:"dropdown-divider"}):_vm._e(),(!_vm.viewingPublicly && _vm.isCmd && _vm.legoDoll.executionEnv !== 'variable')?_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":function($event){return _vm.$emit('emitOpenPersonalNoteModal')}}},[_vm._v("Add/Edit Personal Note")]):_vm._e(),(!_vm.cannotEditKit)?[_c('hr',{staticClass:"dropdown-divider"}),_c('b-dropdown-item',{attrs:{"custom":""}},[_c('div',{staticClass:"tw-font-bold tw-text-xs text-gray-600"},[_vm._v(" ENGINEER ZONE ")])]),_c('b-dropdown-item',{on:{"click":_vm.copyParamsAsJSON}},[_vm._v("Copy params as JSON")]),(_vm.isCmd)?_c('b-dropdown-item',{attrs:{"disabled":_vm.legoDoll.executionEnv === 'variable'},on:{"click":function($event){return _vm.$emit('emitCopyExcelFormula')}}},[_vm._v("Copy to spreadsheet formula")]):_vm._e(),_c('b-dropdown-item',{attrs:{"aria-role":"listitem","disabled":_vm.legoDoll.executionEnv === 'variable'},on:{"click":_vm.openExportCommandModal}},[_vm._v(" Copy CLI command ")]),(!_vm.viewingPublicly)?_c('b-dropdown-item',{attrs:{"disabled":_vm.legoDoll.executionEnv === 'variable'},on:{"click":function($event){return _vm.copyRunStatement(_vm.legoDoll.kitRunSchema)}}},[_vm._v("Copy Run Statement")]):_vm._e(),(
          !_vm.cannotEditKit &&
          !_vm.isCmd &&
          _vm.kitDoll.cells.length > 1 &&
          _vm.legoDoll.executionEnv !== 'variable'
        )?_c('hr',{staticClass:"dropdown-divider"}):_vm._e(),(!_vm.cannotEditKit && !_vm.isCmd && _vm.kitDoll.cells.length > 1)?_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":function($event){return _vm.$emit('emitOpenReorderSidePanel')}}},[_vm._v("Reorder")]):_vm._e(),(
          !_vm.viewingPublicly &&
          (_vm._isAzureFunction(_vm.legoDoll.cloudId) ||
            _vm._isLambda(_vm.legoDoll.cloudId)) &&
          _vm.legoDoll.executionEnv !== 'variable' &&
          !_vm.isCopycatKit
        )?[_c('hr',{staticClass:"dropdown-divider"}),_c('b-dropdown-item',{on:{"click":_vm.openLogsInNewTab}},[_vm._v("Go to Logs")])]:_vm._e(),(_vm.legoDoll.kitRunSchema.length > 0)?_c('hr',{staticClass:"dropdown-divider"}):_vm._e(),(
          !_vm.viewingPublicly &&
          _vm.$store.state.activeOrg.role == 'admin' &&
          _vm.legoDoll.executionEnv !== 'variable' &&
          !_vm.isCopycatKit
        )?_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":_vm.syncAlert}},[_vm._v("Sync - Now in [Configure] > Inputs")]):_vm._e(),(
          !_vm.viewingPublicly &&
          _vm.$store.state.activeOrg.role == 'admin' &&
          _vm.legoDoll.executionEnv !== 'variable' &&
          !_vm.isCopycatKit
        )?_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":_vm.copyAlert}},[_vm._v("Copy - Now in [Configure] > [Duplicate]")]):_vm._e(),(!_vm.cannotEditKit && !_vm.isCmd && !_vm.viewingPublicly)?_c('hr',{staticClass:"dropdown-divider"}):_vm._e(),(!_vm.cannotEditKit && !_vm.isCmd && !_vm.viewingPublicly)?_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":_vm.deleteNotebookCell}},[_c('span',{staticClass:"tw-text-red-600"},[_vm._v("Remove ")])]):_vm._e()]:_vm._e()],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }